export { hasLocalOverrideFlag, LOCAL_FF_OVERRIDES } from './overrides'

export const defaultUser = {
  key: process.env.PRODUCT_NAME,
}

export const clientConfig = {
  user: defaultUser,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  options: {
    streaming: false,
  },
}

export enum FEATURE_FLAG_TYPES {
  ACCOUNT_CREATED_JOURNEY_ENABLED = 'account-created-journey-enabled',
  ADMIN_API_QUEUE = 'admin-api-queue',
  ALFIE_DEFAULT_REDIRECT_QUESTION_SET = 'alfie-default-redirect-question-set',
  ALFIE_REVIEW_BP_CTA_ENABLED = 'alfie-review-bp-cta-enabled',
  ALVIN_CPL_REDIRECT = 'alvin-cpl-redirect', // Short Lived
  AMC_APPRAISAL_ENABLED = 'amc-appraisal-enabled',
  API_QUEUE = 'api-queue',
  ASSIGN_LO = 'assign-lo-top-of-funnel',
  AUS_OPEN_AI_ERROR_PROCESSING = 'aus-open-ai-error-processing',
  AUTH0_WEBHOOK_PROCESSOR_LAMBDA_ENABLED = 'auth0-webhook-processor-lambda-enabled',
  AUTH0_WEBHOOK_PROCESSOR_LOGGING_ENABLED = 'auth0-webhook-processor-logging-enabled',
  AUTO_ASSIGN_LOAN_OFFICER_ENABLED = 'auto-assign-loan-officer-enabled',
  AUTO_ICE_BOX_LAMBDA_ENABLED = 'auto-ice-box-lambda-enabled',
  AUTO_ZERO_LOGGING_THRESHOLD = 'auto-zero-logging-threshold',
  AUTOCONTACT_QUEUE_LAMBDA_ENABLED = 'autocontact-queue-lambda-enabled',
  AUTOCONTACT_QUEUE_LOGGING_ENABLED = 'autocontact-queue-logging-enabled',
  AUTOMATED_FOLLOWUP_TEXT_MESSAGES = 'automated-followup-text-messages',
  AUTOMATED_TEXT_MESSAGES_MONARCH_EVALUATION = 'automated-text-messages-monarch-evaluation',
  AUTOMATED_TEXT_MESSAGES_SECOND_MESSAGE = 'automated-text-messages-second-message',
  AUTOMATED_TEXT_MESSAGES_SKIP_TIME_ZONE_CHECK = 'automated-text-messages-skip-time-zone-check',
  AUTOMATED_VOICEMAIL_INSTEAD_OF_TEXT = 'automated-voicemail-instead-of-text',
  BANKRATE_COMPARE_CASHOUT_VARIATION = 'bankrate-compare-cashout-variation',
  BANKRATE_COMPARE_ENABLED = 'bankrate-compare-enabled',
  BANKRATE_MODAL_QUESTION_SETS = 'bankrate-modal-question-sets',
  BAP_DATA_SYNC_CREATE_APPLICATIONS = 'bap-data-sync-create-applications',
  BAP_DATA_SYNC_LAMBDA_ENABLED = 'bap-data-sync-lambda-enabled',
  BP_ITERABLE_V1 = 'bp-iterable-v1',
  CALENDLY_WEBHOOK_PROCESSOR_LAMBDA_ENABLED = 'calendly-webhook-processor-lambda-enabled',
  CIC_SLACK_NOTIFICATION_ENABLED = 'cic-slack-notification-enabled',
  COMMUNICATION_LOG_RESOLVER_BYPASSED = 'communication-log-resolver-bypassed',
  COMPARE_LIGHTNING_TEST_ENABLED = 'compare-lightning-test-enabled',
  CONDITION_NOTIFICATION_ENABLED = 'condition-notification-enabled',
  CORELOGIC_PREQUAL = 'corelogic-prequal',
  DEBUG_NTL_QUALIFICATION = 'debug-ntl-qualification',
  DEFAULT_QUESTIONNAIRE_SLUG = 'default-questionnaire-slug',
  DEFAULT_QUESTIONNAIRE_SLUG_PURCHASE = 'default-questionnaire-slug-purchase',
  DOCUSIGN_WEBHOOK_PROCESSOR_LAMBDA_ENABLED = 'docusign-webhook-processor-lambda-enabled',
  DROPCOWBOY_WEBHOOK_PROCESSOR_LAMBDA_ENABLED = 'dropcowboy-webhook-processor-lambda-enabled',
  DROPCOWBOY_WEBHOOK_LOGGING_ENABLED = 'dropcowboy-webhook-logging-enabled',
  ELLO_BLOCK_UPDATE_ENABLED = 'ello-block-update-enabled',
  ELLO_SYNC_CREATE_LOAN_ENABLED = 'ello-sync-create-loan-enabled',
  ELLO_SYNC_ENCOMPASS_PUSH_TO_PIPELINE = 'ello-sync-encompass-push-to-pipeline',
  ELLO_SYNC_LOAN_ENABLED = 'ello-sync-loan-enabled',
  ELLO_SYNC_LOGGING_ENABLED = 'ello-sync-logging-enabled',
  ELLO_SYNC_PROCESSOR_LAMBDA_ENABLED = 'ello-sync-processor-lambda-enabled',
  ELLO_SYNC_SEND_LOAN_AUDIT_SLACK_ENABLED = 'ello-sync-send-loan-audit-slack-enabled',
  ELLO_SYNC_UPDATE_NEW_MESSAGES = 'ello-sync-update-new-messages',
  ELLO_SYNC_UPDATE_NEW_NOTES = 'ello-sync-update-new-notes',
  EMAIL_PROCESSOR_LAMBDA_ENABLED = 'email-processor-lambda-enabled',
  EMAIL_SUBSCRIPTION_CACHE_ENABLED = 'email-subscription-cache-enabled',
  EMAIL_USER_CACHE_ENABLED = 'email-user-cache-enabled',
  EMAIL_LOGGING_ENABLED = 'email-logging-enabled',
  EMAIL_VALIDATOR_LAMBDA_ENABLED = 'email-validator-lambda-enabled',
  EMAIL_VALIDATOR_LOGGING_ENABLED = 'email-validator-logging-enabled',
  ENABLE_ADVISOR_FORM_SEND_EMAIL = 'enable-advisor-form-send-email',
  ENABLE_AUS_ANALYSIS = 'enable-aus-analysis',
  ENABLE_AUS_DU_PAYLOAD = 'enable-aus-du-payload',
  ENABLE_BP_ACCOUNTS_PAGE = 'enable-bp-accounts-page',
  ENABLE_BP_DOCUMENTS_PAGE = 'enable-bp-documents-page',
  ENABLE_BP_NEWSFEED_REALTOR_QUESTION = 'enable-bp-newsfeed-realtor-question',
  ENABLE_BP_OVERVIEW_PAGE = 'enable-bp-overview-page',
  ENABLE_BP_OVERVIEW_PAGE_VERSION_3 = 'enable-bp-overview-page-version-3',
  ENABLE_BP_REALTOR_SEND_EMAIL = 'enable-bp-realtor-send-email',
  ENABLE_BP_REGGORA_PAGE = 'enable-bp-reggora-page',
  ENABLE_CERTIFIED_CREDIT_DEBUG_LOGGING = 'enable-certified-credit-debug-logging',
  ENABLE_COMPARE_LE_DEMO = 'enable-compare-le-demo',
  ENABLE_CREDIT_REPORT_VGS_ACCOUNT_ALIAS = 'enable-credit-report-vgs-account-alias',
  ENABLE_DUPLICATION_SERVICE_LOAN_OFFICER_ASSIGNMENT = 'enable-duplication-service-loan-officer-assignment',
  ENABLE_ENCOMPASS_COMPLIANCE_REVIEW = 'enable-encompass-compliance-review',
  ENABLE_MILITARY_FIELD_SYNC = 'enable-military-field-sync',
  ENABLE_PREQUAL_EDIT = 'enable-purchase-prequal',
  ENABLE_SAGE_HOME_LOANS_REDIRECT = 'enable-sage-home-loans-redirect',
  ENABLE_STORYBLOK_FOLDERS = 'enable-storyblok-folders',
  ENABLE_TU_MORTGAGE_RATE_ESTIMATES = 'enable-tu-mortgage-rate-estimates',
  ENABLE_VALUE_PROPS_MODAL = 'enable-value-props-modal',
  ENABLE_WF_DEMO = 'enable-wf-demo',
  ENABLED_EVENTING_EVENTS = 'enabled-eventing-events',
  ENCOMPASS_ATTACHMENT_SYNC_ENABLED = 'encompass-attachment-sync-enabled',
  ENCOMPASS_AUTO_LOCK_REQUEST_ENABLED = 'encompass-auto-lock-request-enabled',
  ENCOMPASS_AUTOMATED_DISCLOSURES_ENABLED = 'encompass-automated-disclosures-enabled',
  ENCOMPASS_CONDITION_SYNC_ENABLED = 'encompass-condition-sync-enabled',
  PRELIMINARY_CONDITION_SYNC_ENABLED = 'preliminary-condition-sync-enabled',
  AUTO_SYNC_CONDITIONS_ENABLED = 'auto-sync-conditions-enabled',
  ENCOMPASS_UNDERWRITING_CONDITION_SYNC_ENABLED = 'encompass-underwriting-condition-sync-enabled',
  ENCOMPASS_ENABLE_PROCESSOR_SYNC = 'encompass-enable-processor-sync',
  ENCOMPASS_FUNDED_SYNC_ENABLED = 'encompass-funded-sync-enabled',
  ENCOMPASS_RESOURCE_LOCK_PLUGIN_ENABLED = 'encompass-resource-lock-plugin-enabled',
  ENCOMPASS_WEBHOOK_LOGGING_ENABLED = 'encompass-webhook-logging-enabled',
  ENCOMPASS_WEBHOOK_PROCESSOR_LAMBDA_ENABLED = 'encompass-webhook-processor-lambda-enabled',
  ENCOMPASS_TEMPLATE_UPDATE_ENABLED = 'encompass-template-update-enabled',
  ENCOMPASS_SET_LATE_FEES = 'encompass-set-late-fees',
  ENCOMPASS_TEMPLATE_FOLDERS_ENABLED = 'encompass-template-folders-enabled',
  ESSENT_QUOTING_ENABLED = 'essent-quoting-enabled',
  EVENT_MODEL_LOGGING_ENABLED = 'event-model-logging-enabled',
  EVENT_MODEL_PROCESSOR_LAMBDA_ENABLED = 'event-model-processor-lambda-enabled',
  EXPOSE_MORTGAGE_RATE_ESTIMATES = 'expose-mortgage-rate-estimates',
  FINICITY_WEBHOOK_LOGGING_ENABLED = 'finicity-webhook-logging-enabled',
  FINICITY_WEBHOOK_PROCESSOR_LAMBDA_ENABLED = 'finicity-webhook-processor-lambda-enabled',
  GENERATE_PRELIMINARY_CONDITIONS = 'generate-preliminary-conditions',
  HOMEFINITY_PEAK_SPLIT_PERCENT = 'homefinity-peak-split-percent',
  ICE_ENABLED_STATES = 'ice-enabled-states',
  LEAD_ASSIGNMENT_DIRECT_MESSAGE = 'lead-assignment-direct-message',
  LENDER_ENABLED_LOS = 'lender-enabled-los',
  LENDER_ENABLED_STATES = 'lender-enabled-states',
  LO_ASSIGNMENT_USE_WEEKLY_COUNT = 'lo-assignment-use-weekly-count',
  LO_LENDER_LICENSE_ENABLED = 'lo-lender-license-enabled',
  LOAN_OFFICER_APPLICATION_REMINDER_REMINDERS_ENABLED = 'loan-officer-application-reminder-reminders-enabled',
  LOAN_OFFICER_REMINDERS_ENABLED = 'loan-officer-reminders-enabled',
  LOAN_OFFICER_REMINDERS_EXCLUDED_USERNAMES = 'loan-officer-reminders-excluded-usernames',
  LOG_FEE_REQUEST_ENABLED = 'log-fee-request-enabled',
  MAX_PURCHASE_PRICE_WITH_POINTS_LOGGING_ENABLED = 'max-purchase-price-with-points-logging-enabled',
  NEXUSS_ENABLE_STATES = 'nexuss-enable-states',
  NTL_DISABLED_QUESTION_SETS = 'ntl-disabled-question-sets',
  NTL_HARD_QUAL_MORTGAGE_ADDRESS_CHECK = 'ntl-hard-qual-mortgage-address-check',
  NTL_HARD_QUAL_MORTGAGE_CHECK = 'ntl-hard-qual-mortgage-check',
  PASSWORDLESS_WORKFLOW = 'passwordless-workflow',
  PHONE_FLOW_ENABLED = 'phone-flow-enabled',
  REDIRECT_FROM_UPLOAD_DOCS_PAGE = 'redirect-from-upload-docs-page',
  REPORTING_VIEW_REFRESH_LAMBDA_ENABLED = 'reporting-view-refresh-lambda-enabled',
  RING_CENTRAL_CONTACTS = 'ring-central-contacts-enabled',
  SAGE_CLOSING_CREDIT_OFFER_AMOUNT = 'sage-closing-credit-offer-amount',
  SHOW_BORROWER_PORTAL_V2 = 'show-borrower-portal-v2',
  SHOW_OVERVIEW_SCREEN = 'show-borrower-portal-overview-screen',
  SHOW_PRICING_STEP_LEFT_RAIL = 'show-pricing-step-left-rail',
  SHOW_SIDEBAR_LO_CARD = 'show-sidebar-lo-card',
  SIDEBAR_ENABLED = 'sidebar-enabled',
  SILK_CACHE_ENABLED = 'silk-cache-enabled',
  SKIP_BORROWER_PORTAL = 'skip-borrower-portal',
  SNS_APPLICATION_PUBLISH = 'sns-application-publish',
  TARGET_REGGORA_APPLICATIONS = 'target-reggora-applications',
  TEST = 'launch-darkly-setup',
  TEST_ICE_DEFAULTS = 'test-ice-defaults',
  TEST_USERS_PASS_SOFT_PULL = 'test-users-pass-soft-pull',
  TOGGLE_NEW_DEBT_TO_INCOME_CALCULATION = 'toggle-new-debt-to-income-calculation',
  TRID_COUNTDOWN_REMINDERS_ENABLED = 'trid-countdown-reminders-enabled',
  TRID_SCHEDULED_ENCOMPASS_PUSH = 'trid-scheduled-encompass-push',
  TRIGGER_VELOCIFY_ERROR = 'trigger-velocify-error',
  USE_GOOGLE_TIME_ZONE_API = 'use-google-time-zone-api',
  USE_POINTS_IN_MAX_PURCHASE_PRICE = 'use-points-in-max-purchase-price',
  VALIDATE_CREDIT_PULL_REQUEST = 'validate-credit-pull-request',
  VALUE_PROPS_MODAL_QUESTION_SETS = 'value-props-modal-question-sets',
  VELOCIFY_V2_REDIRECT_URLS = 'velocify-v2-redirect-urls',
  VIP_LOAN_OFFICER_ASSIGNMENT_ENABLED = 'vip-loan-officer-assignment-enabled',
  LOAN_PROGRAM_RULES_ENABLED = 'loan-program-rules-enabled',
  AI_SCHEDULED_PRICING_ELIGIBILITY = 'ai-scheduled-pricing-eligibility',
  ADMIN_API_AUTH0_ENABLED = 'admin-api-auth0-enabled',
  ENABLE_RINGCENTRAL_WEBHOOK_SUBSCRIPTION = 'enable-ringcentral-webhook-subscription',
  ENABLE_AI_CALL_NOTES = 'enable-ai-call-notes',
  ENABLE_RINGCENTRAL_WEBHOOK_CONTACT_LOGGING = 'enable-ringcentral-webhook-contact-logging',
  ENABLE_RINGCENTRAL_JWT_AUTH = 'enable-ringcentral-jwt-auth',
  ENABLE_DOCUMENT_SCANNING = 'enable-document-scanning',
  TESTING_INTERNAL_AUS = 'testing-internal-aus',
}

export type ffKey = keyof typeof FEATURE_FLAG_TYPES
export type ffPayload = boolean | string | string[] | object | number
export type DefaultFeatureFlags = Record<FEATURE_FLAG_TYPES, ffPayload>

export const DEFAULT_FEATURE_FLAGS: DefaultFeatureFlags = {
  [FEATURE_FLAG_TYPES.ADMIN_API_QUEUE]: true,
  [FEATURE_FLAG_TYPES.ALFIE_REVIEW_BP_CTA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ALFIE_DEFAULT_REDIRECT_QUESTION_SET]: false,
  [FEATURE_FLAG_TYPES.ALVIN_CPL_REDIRECT]: true,
  [FEATURE_FLAG_TYPES.AMC_APPRAISAL_ENABLED]: false,
  [FEATURE_FLAG_TYPES.API_QUEUE]: true,
  [FEATURE_FLAG_TYPES.ASSIGN_LO]: true,
  [FEATURE_FLAG_TYPES.AUS_OPEN_AI_ERROR_PROCESSING]: false,
  [FEATURE_FLAG_TYPES.AUTH0_WEBHOOK_PROCESSOR_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.AUTH0_WEBHOOK_PROCESSOR_LOGGING_ENABLED]: false,
  [FEATURE_FLAG_TYPES.AUTO_ASSIGN_LOAN_OFFICER_ENABLED]: false,
  [FEATURE_FLAG_TYPES.AUTO_ICE_BOX_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.AUTO_ZERO_LOGGING_THRESHOLD]: '-1',
  [FEATURE_FLAG_TYPES.AUTOCONTACT_QUEUE_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.AUTOCONTACT_QUEUE_LOGGING_ENABLED]: false,
  [FEATURE_FLAG_TYPES.AUTOMATED_FOLLOWUP_TEXT_MESSAGES]: false,
  [FEATURE_FLAG_TYPES.AUTOMATED_VOICEMAIL_INSTEAD_OF_TEXT]: false,
  [FEATURE_FLAG_TYPES.BANKRATE_COMPARE_CASHOUT_VARIATION]: false,
  [FEATURE_FLAG_TYPES.BANKRATE_COMPARE_ENABLED]: false,
  [FEATURE_FLAG_TYPES.BAP_DATA_SYNC_CREATE_APPLICATIONS]: false,
  [FEATURE_FLAG_TYPES.BAP_DATA_SYNC_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.BP_ITERABLE_V1]: false,
  [FEATURE_FLAG_TYPES.CIC_SLACK_NOTIFICATION_ENABLED]: false,
  [FEATURE_FLAG_TYPES.COMMUNICATION_LOG_RESOLVER_BYPASSED]: false,
  [FEATURE_FLAG_TYPES.COMPARE_LIGHTNING_TEST_ENABLED]: true,
  [FEATURE_FLAG_TYPES.CONDITION_NOTIFICATION_ENABLED]: false,
  [FEATURE_FLAG_TYPES.CORELOGIC_PREQUAL]: false,
  [FEATURE_FLAG_TYPES.DEBUG_NTL_QUALIFICATION]: false,
  [FEATURE_FLAG_TYPES.DEFAULT_QUESTIONNAIRE_SLUG]: 'q-bankrate',
  [FEATURE_FLAG_TYPES.DEFAULT_QUESTIONNAIRE_SLUG_PURCHASE]: 'q-bankrate',
  [FEATURE_FLAG_TYPES.DOCUSIGN_WEBHOOK_PROCESSOR_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.DROPCOWBOY_WEBHOOK_PROCESSOR_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.DROPCOWBOY_WEBHOOK_LOGGING_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ELLO_SYNC_CREATE_LOAN_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ELLO_SYNC_ENCOMPASS_PUSH_TO_PIPELINE]: false,
  [FEATURE_FLAG_TYPES.ELLO_SYNC_LOAN_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ELLO_SYNC_LOGGING_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ELLO_SYNC_PROCESSOR_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ELLO_SYNC_SEND_LOAN_AUDIT_SLACK_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ELLO_SYNC_UPDATE_NEW_NOTES]: false,
  [FEATURE_FLAG_TYPES.ELLO_SYNC_UPDATE_NEW_MESSAGES]: false,
  [FEATURE_FLAG_TYPES.ELLO_BLOCK_UPDATE_ENABLED]: false,
  [FEATURE_FLAG_TYPES.EMAIL_PROCESSOR_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.EMAIL_SUBSCRIPTION_CACHE_ENABLED]: false,
  [FEATURE_FLAG_TYPES.EMAIL_USER_CACHE_ENABLED]: false,
  [FEATURE_FLAG_TYPES.EMAIL_LOGGING_ENABLED]: false,
  [FEATURE_FLAG_TYPES.EMAIL_VALIDATOR_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.EMAIL_VALIDATOR_LOGGING_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENABLE_ADVISOR_FORM_SEND_EMAIL]: false,
  [FEATURE_FLAG_TYPES.ENABLE_AUS_ANALYSIS]: false,
  [FEATURE_FLAG_TYPES.ENABLE_AUS_DU_PAYLOAD]: false,
  [FEATURE_FLAG_TYPES.ENABLE_BP_NEWSFEED_REALTOR_QUESTION]: false,
  [FEATURE_FLAG_TYPES.ENABLE_BP_OVERVIEW_PAGE]: false,
  [FEATURE_FLAG_TYPES.ENABLE_BP_OVERVIEW_PAGE_VERSION_3]: false,
  [FEATURE_FLAG_TYPES.ENABLE_BP_REGGORA_PAGE]: false,
  [FEATURE_FLAG_TYPES.ENABLE_CERTIFIED_CREDIT_DEBUG_LOGGING]: false,
  [FEATURE_FLAG_TYPES.ENABLE_COMPARE_LE_DEMO]: false,
  [FEATURE_FLAG_TYPES.ENABLE_CREDIT_REPORT_VGS_ACCOUNT_ALIAS]: false,
  [FEATURE_FLAG_TYPES.ENABLE_DUPLICATION_SERVICE_LOAN_OFFICER_ASSIGNMENT]: true,
  [FEATURE_FLAG_TYPES.ENABLE_ENCOMPASS_COMPLIANCE_REVIEW]: false,
  [FEATURE_FLAG_TYPES.ENABLE_MILITARY_FIELD_SYNC]: false,
  [FEATURE_FLAG_TYPES.ENABLE_SAGE_HOME_LOANS_REDIRECT]: false,
  [FEATURE_FLAG_TYPES.ENABLE_STORYBLOK_FOLDERS]: false,
  [FEATURE_FLAG_TYPES.ENABLE_TU_MORTGAGE_RATE_ESTIMATES]: false,
  [FEATURE_FLAG_TYPES.ENABLE_VALUE_PROPS_MODAL]: false,
  [FEATURE_FLAG_TYPES.ENABLE_WF_DEMO]: [],
  [FEATURE_FLAG_TYPES.PRELIMINARY_CONDITION_SYNC_ENABLED]: false,
  [FEATURE_FLAG_TYPES.AUTO_SYNC_CONDITIONS_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_AUTO_LOCK_REQUEST_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_CONDITION_SYNC_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_UNDERWRITING_CONDITION_SYNC_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_ENABLE_PROCESSOR_SYNC]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_ATTACHMENT_SYNC_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_AUTOMATED_DISCLOSURES_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_RESOURCE_LOCK_PLUGIN_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_WEBHOOK_LOGGING_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_WEBHOOK_PROCESSOR_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_TEMPLATE_UPDATE_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_FUNDED_SYNC_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_SET_LATE_FEES]: false,
  [FEATURE_FLAG_TYPES.ENCOMPASS_TEMPLATE_FOLDERS_ENABLED]: false,
  [FEATURE_FLAG_TYPES.EVENT_MODEL_PROCESSOR_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.EVENT_MODEL_LOGGING_ENABLED]: false,
  [FEATURE_FLAG_TYPES.EXPOSE_MORTGAGE_RATE_ESTIMATES]: false,
  [FEATURE_FLAG_TYPES.FINICITY_WEBHOOK_LOGGING_ENABLED]: false,
  [FEATURE_FLAG_TYPES.FINICITY_WEBHOOK_PROCESSOR_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.CALENDLY_WEBHOOK_PROCESSOR_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.GENERATE_PRELIMINARY_CONDITIONS]: false,
  [FEATURE_FLAG_TYPES.HOMEFINITY_PEAK_SPLIT_PERCENT]: 0,
  [FEATURE_FLAG_TYPES.ICE_ENABLED_STATES]: [],
  [FEATURE_FLAG_TYPES.LEAD_ASSIGNMENT_DIRECT_MESSAGE]: false,
  [FEATURE_FLAG_TYPES.LENDER_ENABLED_LOS]: [],
  [FEATURE_FLAG_TYPES.LENDER_ENABLED_STATES]: [],
  [FEATURE_FLAG_TYPES.LO_ASSIGNMENT_USE_WEEKLY_COUNT]: false,
  [FEATURE_FLAG_TYPES.LO_LENDER_LICENSE_ENABLED]: false,
  [FEATURE_FLAG_TYPES.LOAN_OFFICER_APPLICATION_REMINDER_REMINDERS_ENABLED]:
    false,
  [FEATURE_FLAG_TYPES.LOAN_OFFICER_REMINDERS_ENABLED]: false,
  [FEATURE_FLAG_TYPES.LOAN_OFFICER_REMINDERS_EXCLUDED_USERNAMES]: [],
  [FEATURE_FLAG_TYPES.LOG_FEE_REQUEST_ENABLED]: false,
  [FEATURE_FLAG_TYPES.NEXUSS_ENABLE_STATES]: [],
  [FEATURE_FLAG_TYPES.NTL_DISABLED_QUESTION_SETS]: [],
  [FEATURE_FLAG_TYPES.NTL_HARD_QUAL_MORTGAGE_ADDRESS_CHECK]: true,
  [FEATURE_FLAG_TYPES.NTL_HARD_QUAL_MORTGAGE_CHECK]: true,
  [FEATURE_FLAG_TYPES.PHONE_FLOW_ENABLED]: false,
  [FEATURE_FLAG_TYPES.REDIRECT_FROM_UPLOAD_DOCS_PAGE]: true,
  [FEATURE_FLAG_TYPES.REPORTING_VIEW_REFRESH_LAMBDA_ENABLED]: false,
  [FEATURE_FLAG_TYPES.SHOW_BORROWER_PORTAL_V2]: false,
  [FEATURE_FLAG_TYPES.SHOW_PRICING_STEP_LEFT_RAIL]: false,
  [FEATURE_FLAG_TYPES.SHOW_SIDEBAR_LO_CARD]: false,
  [FEATURE_FLAG_TYPES.SIDEBAR_ENABLED]: true,
  [FEATURE_FLAG_TYPES.SILK_CACHE_ENABLED]: false,
  [FEATURE_FLAG_TYPES.SNS_APPLICATION_PUBLISH]: false,
  [FEATURE_FLAG_TYPES.SKIP_BORROWER_PORTAL]: false,
  [FEATURE_FLAG_TYPES.TEST_ICE_DEFAULTS]: false,
  [FEATURE_FLAG_TYPES.TEST_USERS_PASS_SOFT_PULL]: false,
  [FEATURE_FLAG_TYPES.TEST]: true,
  [FEATURE_FLAG_TYPES.TOGGLE_NEW_DEBT_TO_INCOME_CALCULATION]: true,
  [FEATURE_FLAG_TYPES.TRID_COUNTDOWN_REMINDERS_ENABLED]: false,
  [FEATURE_FLAG_TYPES.TRID_SCHEDULED_ENCOMPASS_PUSH]: false,
  [FEATURE_FLAG_TYPES.TRIGGER_VELOCIFY_ERROR]: false,
  [FEATURE_FLAG_TYPES.USE_GOOGLE_TIME_ZONE_API]: false,
  [FEATURE_FLAG_TYPES.VALUE_PROPS_MODAL_QUESTION_SETS]: [],
  [FEATURE_FLAG_TYPES.VELOCIFY_V2_REDIRECT_URLS]: false,
  [FEATURE_FLAG_TYPES.VIP_LOAN_OFFICER_ASSIGNMENT_ENABLED]: false,
  [FEATURE_FLAG_TYPES.SHOW_OVERVIEW_SCREEN]: false,
  [FEATURE_FLAG_TYPES.ENABLE_PREQUAL_EDIT]: false,
  [FEATURE_FLAG_TYPES.AUTOMATED_TEXT_MESSAGES_SECOND_MESSAGE]: false,
  [FEATURE_FLAG_TYPES.AUTOMATED_TEXT_MESSAGES_SKIP_TIME_ZONE_CHECK]: false,
  [FEATURE_FLAG_TYPES.AUTOMATED_TEXT_MESSAGES_MONARCH_EVALUATION]: false,
  [FEATURE_FLAG_TYPES.VALIDATE_CREDIT_PULL_REQUEST]: false,
  [FEATURE_FLAG_TYPES.RING_CENTRAL_CONTACTS]: false,
  [FEATURE_FLAG_TYPES.ENABLED_EVENTING_EVENTS]: {},
  [FEATURE_FLAG_TYPES.ENABLE_BP_DOCUMENTS_PAGE]: false,
  [FEATURE_FLAG_TYPES.SAGE_CLOSING_CREDIT_OFFER_AMOUNT]: 0,
  [FEATURE_FLAG_TYPES.ACCOUNT_CREATED_JOURNEY_ENABLED]: false,
  [FEATURE_FLAG_TYPES.BANKRATE_MODAL_QUESTION_SETS]: [],
  [FEATURE_FLAG_TYPES.PASSWORDLESS_WORKFLOW]: false,
  [FEATURE_FLAG_TYPES.ESSENT_QUOTING_ENABLED]: false,
  [FEATURE_FLAG_TYPES.USE_POINTS_IN_MAX_PURCHASE_PRICE]: false,
  [FEATURE_FLAG_TYPES.MAX_PURCHASE_PRICE_WITH_POINTS_LOGGING_ENABLED]: false,
  [FEATURE_FLAG_TYPES.ENABLE_BP_ACCOUNTS_PAGE]: false,
  [FEATURE_FLAG_TYPES.ENABLE_BP_REALTOR_SEND_EMAIL]: false,
  [FEATURE_FLAG_TYPES.LOAN_PROGRAM_RULES_ENABLED]: false,
  [FEATURE_FLAG_TYPES.AI_SCHEDULED_PRICING_ELIGIBILITY]: false,
  [FEATURE_FLAG_TYPES.ADMIN_API_AUTH0_ENABLED]: false,
  [FEATURE_FLAG_TYPES.TARGET_REGGORA_APPLICATIONS]: '-1',
  [FEATURE_FLAG_TYPES.ENABLE_RINGCENTRAL_WEBHOOK_SUBSCRIPTION]: false,
  [FEATURE_FLAG_TYPES.ENABLE_AI_CALL_NOTES]: false,
  [FEATURE_FLAG_TYPES.ENABLE_RINGCENTRAL_WEBHOOK_CONTACT_LOGGING]: false,
  [FEATURE_FLAG_TYPES.ENABLE_RINGCENTRAL_JWT_AUTH]: true,
  [FEATURE_FLAG_TYPES.ENABLE_DOCUMENT_SCANNING]: false,
  [FEATURE_FLAG_TYPES.TESTING_INTERNAL_AUS]: false,
}
